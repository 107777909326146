import {put, takeEvery, select, delay} from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import {apiWebintService} from "../../config/api";
import WebintAction from "./webintAction";
import {
  prepareFetchTargetRequest, transformGroupActivity, transformGroupData, transformPosts100Data, transformPostsData,
  transformWebintSearchProgress,
  transformWebintTargets,
  transformWebintTrends, transformWebintUserStatData
} from "./webintTransformer";
import {
  transformPostFilters,
  transformPostsFilter,
  transformPostStat
} from "../profiler/profilerTransformer";
import axios from "axios";
import config from "../../config/config";
import {authHeader} from "../../helpers/authHeader";
import {notification} from "antd";
import ExportFactory from "../../services/Export/ExportFactory";
import {
  getWebintGroups,
  getWebintImages,
  getWebintPosts,
  getWebintTargets,
  getWebintTask,
  getWebintTopPosts, getWebintVideos
} from "./webintSelector";
import AuditAction from "../audit/auditAction";
import i18n from "i18next";


function* getTask(action) {
  const { id, callback} = action.payload;
  try {
    const {data} = yield apiWebintService.getWebintById(id);
    yield put(WebintAction.updateWebintState({task: data, isNotFound: false}));
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (e?.response?.data?.message === 'NOT_FOUND: Incorrect Webint id') {
      yield put(WebintAction.updateWebintState({isNotFound: true}));
    }
  }
}

function* updateTaskSaga(action) {
  const {id, formData, callback} = action.payload;

  try {
    const {data} = yield axios.put(`${config.api_webint}/${id}`, formData,{
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data"
      }
    });
    console.log('updateWebintSaga', data);
    yield put(WebintAction.getTask(id, () => {}));
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* getGroupsSaga(action) {
  const { id, filterData = {}, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.getGroups(id, filterData);
    const transData = transformGroupData(data);
    yield put(WebintAction.updateWebintState({groups: transData}));
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getTargetsSaga(action) {
  const { id, filterData = {}, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.getTargets(id, filterData);
    const transData = transformWebintTargets(data);
    yield put(WebintAction.updateWebintState({targets: transData}));
    yield callback(transData);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPostsSaga(action) {
  const { id, filters = {}, callback } = action.payload;
  try {
    const transFilters = transformPostsFilter(filters);
    const {data} = yield apiWebintService.webintGetPosts(id, transFilters);
    console.log('getPostsSaga', data);
    //yield delay(500);
    //const groups = yield select(getWebintGroups);
    const transData = transformPostsData(data);
    yield put(WebintAction.updateWebintState({posts: transData}));
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

/*function* getNewsAndUpdatesWebSearchSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getWebResults(id);
    yield put(WebintAction.updateWebintState({webSearch: data}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}*/

function* getSearchProgressSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.webintGetSearchProgress(id);
    const transformedData = transformWebintSearchProgress(data);
    yield put(WebintAction.updateWebintState({searchProgress: transformedData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getImagesSaga(action) {
  const { id, filterData, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.getImages(id, filterData);
    yield put(WebintAction.updateWebintState({images: data}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getVideosSaga(action) {
  const { id, filterData, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.getVideos(id, filterData);
    yield put(WebintAction.updateWebintState({videos: data}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getTopPostsSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.webintGetPosts(id, {sort:{field:"commentsCount",direction:"DESC"}, batch: 10});
    console.log('getTopPostsSaga', data);
    //yield delay(500);
    //const groups = yield select(getWebintGroups);
    const transData = transformPostsData(data);
    yield put(WebintAction.updateWebintState({topPosts: transData}));
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getTrendsSaga(action) {
  const { keyword, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.getTrends(keyword);
    const transData = transformWebintTrends(data);
    console.log('getTrendsSaga', transData);
    yield callback(transData);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPostStatSaga(action) {
  const { id, filters, callback } = action.payload;
  const postFilters = transformPostFilters(filters);
  //console.log('filters', postFilters);
  try {
    const {data} = yield apiWebintService.getWebintPostStat(id, postFilters);
    const transData = transformPostStat(data, postFilters);
    yield put(WebintAction.updateWebintState({postStat: transData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* fetchTargetSaga(action) {
  const {id, target, callback} = action.payload;

  try {
    console.log('fetchTargetSaga', id, target);
    const preparedPost = prepareFetchTargetRequest(target);
    console.log('preparedPost', ...preparedPost);

    const {data} = yield axios.post(`${config.api_profile}/create-profile`, preparedPost,{
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data"
      }
    });
    console.log('data!', data);
    const [addedProfile] = data;
    const {caseNumber} = yield select(getWebintTask);
    const {data: secondData} = yield apiWebintService.webintAssignProfileDataItem(addedProfile.id, target.id, caseNumber);
    console.log('secondData!', secondData);
    yield callback(addedProfile.id);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* fetchGroupSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.runCrawler(id);
    console.log('fetchGroupSaga', data);
    notification.success({message: i18n.t('Group Crawlers running has been started successfully'), placement: 'bottomRight'});
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* fetchTaskSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.runCrawler(id);
    notification.success({message: i18n.t('Crawlers running has been started successfully'), placement: 'bottomRight'});
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPostAvatarSaga(action) {
  const { postId, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.getPostAvatar(postId);
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPostHistorySaga(action) {
  const { id, postId, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.getPostHistory(id, postId);
    console.log('getPostHistorySaga', data);
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getGroupsActivitySaga(action) {
  const {invId, filters, callback} = action.payload;
  try {
    const {data} = yield apiWebintService.getGroupActivity(invId, filters);
    const transData = transformGroupActivity(data);
    yield callback(transData);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getInvestigationGroupsSaga(action) {
  const {invId, callback} = action.payload;
  try {
    const {data} = yield apiWebintService.getInvGroups(invId);
    console.log('getInvestigationGroupsSaga', data);
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportSaga(action) {
  try {
    const {type, sections} = action.payload;

    const task = yield select(getWebintTask);
    const targets = yield select(getWebintTargets);
    const groups = yield select(getWebintGroups);
    const posts = yield select(getWebintPosts);
    const topPosts = yield select(getWebintTopPosts);
    const images = yield select(getWebintImages);
    const videos = yield select(getWebintVideos);

    const exportData = {
      task,
      targets,
      groups,
      posts,
      topPosts,
      images,
      videos,

      sections,
    };

    notification.success({message: i18n.t('The system is generating your export file. Do not close this tab, please.'), placement: 'bottomRight', duration: 3});
    yield put(WebintAction.updateWebintState({ exportLoading: true}));

    const exportInstance = yield ExportFactory.getExportHandler(type, exportData);
    yield exportInstance.prepareWebint();
    yield exportInstance.export();

    yield put(WebintAction.updateWebintState({ exportLoading: false}));
    yield put(AuditAction.createAudit({details: "EXPORT", actionType: "WEBINT", id: task?.id}));

  } catch (e) {
    yield put(WebintAction.updateWebintState({ exportLoading: true}));
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPosts100Saga(action) {
  const {filters, callback} = action.payload;
  try {
    //@TODO: send filters to updated endpoint
    const {data} = yield apiWebintService.getTopPosts(filters?.from, filters?.to);
    const transData = transformPosts100Data(data);
    console.log('getPosts100Saga', transData);
    yield put(WebintAction.updateWebintState({posts100: transData}));
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportPosts100Saga(action) {
  try {
    const {type, selectedPosts} = action.payload;

    yield put(WebintAction.updateWebintState({ exportLoading: true}));
    const exportInstance = yield ExportFactory.getExportHandler(type, selectedPosts);
    yield exportInstance.preparePosts100();
    yield exportInstance.export();
    yield put(WebintAction.updateWebintState({ exportLoading: false}));
  } catch (e) {
    yield put(WebintAction.updateWebintState({ exportLoading: true}));
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportGroupsActivitySaga(action) {
  try {
    const {type, activityData} = action.payload;

    const exportInstance = yield ExportFactory.getExportHandler(type, activityData);
    yield exportInstance.prepareGroupsActivity();
    yield exportInstance.export();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}
function* exportWebintUserStatSaga(action) {
  try {
    const {type, statData, filters} = action.payload;

    if (!filters?.startDate && !filters?.userName && !filters?.isMonitoring) {
      notification.success({message: i18n.t('The system is forming an export document of the last 1000 records. Please do not close the window'), placement: 'bottomRight'});
    }
    const {data} = yield apiWebintService.getUserStat({...filters, batch: 1000});
    const transData = transformWebintUserStatData(data.content);
    //console.log('transData', transData, statData);

    const exportInstance = yield ExportFactory.getExportHandler(type, /*statData*/transData);
    yield exportInstance.prepareWebintUserStat();
    yield exportInstance.export();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getWebintStatSaga(action) {
  const callback = action.payload;
  try {
    const {data} = yield apiWebintService.getWebintStat();
    //console.log('getWebintStatSaga', data);
    yield callback(data);
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getWebintUserStatSaga(action) {
  const {filters, callback} = action.payload;
  try {
    const {data} = yield apiWebintService.getUserStat(filters);
    const transData = transformWebintUserStatData(data.content);
    //console.log('getWebintUserStatSaga', transData);
    yield callback({...data, transContent: transData});
  } catch (e) {
    yield callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getPostAnalysisSaga(action) {
  const { targetId, postId, callback } = action.payload;
  try {
    const {data} = yield apiWebintService.webintPostAnalyze(targetId, postId);
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

export function* watchWebint() {
  yield takeEvery(WebintAction.GET_TASK, getTask);
  yield takeEvery(WebintAction.UPDATE_TASK, updateTaskSaga);
  yield takeEvery(WebintAction.GET_GROUPS, getGroupsSaga);
  yield takeEvery(WebintAction.GET_TARGETS, getTargetsSaga);
  yield takeEvery(WebintAction.GET_POSTS, getPostsSaga);
  yield takeEvery(WebintAction.GET_SEARCH_PROGRESS, getSearchProgressSaga);
  yield takeEvery(WebintAction.GET_IMAGES, getImagesSaga);
  yield takeEvery(WebintAction.GET_VIDEOS, getVideosSaga);
  yield takeEvery(WebintAction.GET_TOP_POSTS, getTopPostsSaga);
  yield takeEvery(WebintAction.GET_TRENDS, getTrendsSaga);
  yield takeEvery(WebintAction.GET_POST_STAT, getPostStatSaga);
  yield takeEvery(WebintAction.FETCH_TARGET, fetchTargetSaga);
  yield takeEvery(WebintAction.FETCH_GROUP, fetchGroupSaga);
  yield takeEvery(WebintAction.FETCH_TASK, fetchTaskSaga);
  yield takeEvery(WebintAction.GET_POST_AVATAR, getPostAvatarSaga);
  yield takeEvery(WebintAction.GET_POST_HISTORY, getPostHistorySaga);
  yield takeEvery(WebintAction.GET_POST_100, getPosts100Saga);
  yield takeEvery(WebintAction.GET_GROUPS_ACTIVITY, getGroupsActivitySaga);
  yield takeEvery(WebintAction.GET_INVESTIGATION_GROUPS, getInvestigationGroupsSaga);
  yield takeEvery(WebintAction.GET_WEBINT_STAT, getWebintStatSaga);
  yield takeEvery(WebintAction.GET_WEBINT_USER_STAT, getWebintUserStatSaga);
  yield takeEvery(WebintAction.EXPORT, exportSaga);
  yield takeEvery(WebintAction.EXPORT_POSTS_100, exportPosts100Saga);
  yield takeEvery(WebintAction.EXPORT_GROUPS_ACTIVITY, exportGroupsActivitySaga);
  yield takeEvery(WebintAction.EXPORT_WEBINT_USER_STAT, exportWebintUserStatSaga);
  yield takeEvery(WebintAction.GET_POST_ANALYSIS, getPostAnalysisSaga);

}
