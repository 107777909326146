import { delay, put, select, takeEvery } from "redux-saga/effects";
import AmmAction from "./ammAction";
import SettingsAction from "../settings/settingsAction";
import {
  prepareCreateAvatar,
  prepareCreateMetaAvatar,
  prepareLogsCattegories,
  prepareSaveProxy,
  prepareUpdateAvatar,
  prepareUpdateMetaAvatar,
  transformBlockedAvatars,
  transformIntoNodeTost,
  transformMetaAvatar,
  transformMetaAvatarsList,
} from "./ammTransformer";
import { apiAuditService, apiAvatarsService } from "../../config/api";
import { notification } from "antd";
import moment from "moment";
import axios from "axios";
import ExportFactory from "../../services/Export/ExportFactory";
import {getAmmAuditLog, getAmmMetaAvatars} from "./ammSelector";
import {getAvatarActivities, getAvatarBlocked} from "../avatar/avatarSelector";
import AuditAction from "../audit/auditAction";
import i18n from "i18next";

function* updateAvatarQuarantine(action) {
  const { data/*, callback*/ } = action.payload;
  try {
    //yield apiAvatarsService.updateQuarantineMode(data.id, data);
    yield apiAvatarsService.useAutoQuarantine(data.id, data);
    yield put(
      AmmAction.updateAvatarQuarantineLocal({
        id: data.id,
        useQuarantine: data.useQuarantine,
      })
    );
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* updateAvatarStatus(action) {
  const { data/*, callback*/ } = action.payload;
  try {
    yield apiAvatarsService.updateAvatarStatus(data.id, data);
    yield put(
      AmmAction.updateAvatarStatusLocal({ id: data.id, status: data.status })
    );
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getMetaAvatarsSaga(action) {
  const { filterData, callback = null } = action.payload;

  try {
    const { data } = yield apiAvatarsService.search(filterData);
    const preparedData = transformMetaAvatarsList(data);
    const preparedBlocked = transformBlockedAvatars(data);
    yield put(AmmAction.setMetaAvatarsList(preparedData));
    yield put(AmmAction.updateAmmState({ blockedAvatars: preparedBlocked }));

    if (callback) yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* updateProfileInfo(action) {
  const { data, refreshState = false, callback = null } = action.payload;
  try {
    const preparedPost = yield prepareUpdateAvatar(data);
    const {data: {avatar}} = yield apiAvatarsService.update(data.id, preparedPost);
    console.log('updated.profile saga', data.id, avatar);
    if (!refreshState) {
      yield put(AmmAction.updateAmmProfilesState(data.id, avatar));
    }

    if (callback) yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* createMetaAvatarSaga(action) {
  const { data, callback = null } = action.payload;
  try {
    const preparedPost = yield prepareCreateMetaAvatar(data);
    /*const created =*/ yield apiAvatarsService.create(preparedPost);

    if (callback) yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (callback) yield callback(false);
  }
}

function* updateMetaAvatarSaga(action) {
  const { id, data, callback = null } = action.payload;
  try {
    const preparedPost = yield prepareUpdateMetaAvatar(data);
    const updated = yield apiAvatarsService.updateMetaAvatar(id, preparedPost);
    if (updated) {
      yield put(AmmAction.getMetaAvatar(id));
    }
    if (callback) yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (callback) yield callback(false);
  }
}

function* deleteMetaAvatarSaga(action) {
  const { name, id, callback = null } = action.payload;

  try {
    const deleted = yield apiAvatarsService.deleteById(id);
    yield put(
      SettingsAction.showNotification(`${i18n.t('Avatar')} ${name} ${i18n.t('was deleted')}`, "success")
    );
    if (deleted) {
      yield put(AmmAction.deleteMetaAvatarSuccess(id));
    }

    if (callback) yield callback(id);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (callback) yield callback(false);
  }
}

function* getMetaAvatarStatSaga(action) {
  console.log("trying to get statt");
  const id = action.payload;
  try {
    const { data: metaAvatarStat } = yield apiAvatarsService.getAvatarStatistic(
      id
    );
    yield put(AmmAction.updateAmmState(metaAvatarStat));
  } catch (e) {
    SettingsAction.handleAPIError(e);
  }
}

function* getMetaAvatarSaga(action) {
  try {
    yield put(AmmAction.updateAmmState({ metaAvatarLoading: true }));
    const { data: metaAvatar } = yield apiAvatarsService.getMetaAvatarById(
      action.payload
    );
    const preparedMetaAvatar = transformMetaAvatar(metaAvatar);
    yield put(AmmAction.updateAmmState({ metaAvatar: preparedMetaAvatar }));
    yield put(AmmAction.updateAmmState({ metaAvatarLoading: false }));
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield put(AmmAction.updateAmmState({ metaAvatarLoading: false }));
  }
}

function* createProfileSaga(action) {
  const { id, postData, callback = null } = action.payload;
  try {
    const preparedPost = prepareCreateAvatar(postData);
    const { data: created } = yield apiAvatarsService.addAvatar(
      id,
      preparedPost
    );
    const { avatar, status } = created;
    if (avatar) {
      yield delay(1000);
      yield put(AmmAction.getMetaAvatar(id));
      callback(true);
    } else {
      // eslint-disable-next-line
      switch (status) {
        case "AVATAR_ALREADY_EXISTS":
          notification.error({
            message: "Avatar already exists",
            placement: "bottomRight",
          });
          break;
      }
      callback(false);
    }
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (callback) yield callback(false);
  }
}

function* deleteProfileSaga(action) {
  const { id, callback, messageCallback } = action.payload;
  try {
    const { data } = yield apiAvatarsService.deleteAvatarById(id);
    console.log(data);
    if (!data.isDelete && !data.schedules) {
      yield put(SettingsAction.handleAPIError({ message: `${data.message}.` }));
    } else if (!data.isDelete && data?.schedules?.length) {
      yield put(
        SettingsAction.handleAPIError({
          // message: `${data.message}.\n${data.schedules
          //   .map((el) => el.id)
          //   .join("\n")}`,
          message: transformIntoNodeTost(
            `${data.message}.\n`,
            data.schedules,
            messageCallback
          ),
        })
      );
    } else {
      yield put(SettingsAction.showNotification(`${data.message}.`));
      yield delay(1000);
    }
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* saveDefaultProxySaga(action) {
  const { id, proxyData, callback = null } = action.payload;
  try {
    const postData = prepareSaveProxy(proxyData);
    const success = apiAvatarsService.updateProxy(id, postData);
    if (success) {
      notification.success({
        message: "Default proxy saved successfully",
        placement: "bottomRight",
      });
    }
    if (callback) yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (callback) yield callback(false);
  }
}

function* getAuditLogSaga(action) {
  const { from, to, callback } = action.payload;
  const filters = {
    dateFrom: from || moment().add(-30, "days").format("x"),
    dateTo: to || moment().format("x"),
  };

  console.log("from", from);

  try {
    const { data } = yield apiAvatarsService.getAllAvatarLogs(filters);
    const cattegories = yield prepareLogsCattegories(data);
    yield put(
      AmmAction.updateAmmState({
        auditLog: data,
        auditLogCattegories: cattegories,
      })
    );

    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* exportAuditLogSaga(action) {
  const { type, callback } = action.payload;
  try {
    const log = yield select(getAmmAuditLog);
    const exportInstance = yield ExportFactory.getExportHandler(type, log);
    yield exportInstance.prepareAvatarAudit();
    yield exportInstance.export();

    yield put(AuditAction.createAudit({details: "EXPORT_LOGS", logType: "Logs"}));

    callback(true);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getApaLoginSaga(action) {
  const { url, avatar, currentUser, callback } = action.payload;
  try{
    yield apiAuditService.apaAudit(
      {
        actionType:"AVATARS", 
        details:"APA_AVATARS_INTERACTION", 
        login:avatar.username, 
        domain:avatar.domain,
        userName: currentUser.username,
        role: currentUser.roles.includes("ADMIN")? "ADMIN" : "USER",
      },
      'Bh+kvRBL/+WABVtsy76giOwGHtLtMGEPgu9lfpvq');
    const { data } = yield axios.get(url);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* getApaPostSaga(action) {
  const { url, callback } = action.payload;
  try{
    const { data } = yield axios.get(url);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* exportActivitiesSaga(action) {
  const { type, avatarId, callback } = action.payload;
  try {
    const activities = yield select(getAvatarActivities);
    const exportInstance = yield ExportFactory.getExportHandler(
      type,
      activities
    );
    yield exportInstance.prepareAvatarActivities();
    yield exportInstance.export();

    yield put(AuditAction.createAudit({details: "EXPORT_LOGS", logType: "Activities", id: avatarId}));

    callback(true);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportBlockedSaga(action) {
  const { type, avatarId, callback } = action.payload;
  try {
    const blocked = yield select(getAvatarBlocked);
    const exportInstance = yield ExportFactory.getExportHandler(type, blocked);
    yield exportInstance.prepareAvatarBlocked();
    yield exportInstance.export();

    yield put(AuditAction.createAudit({details: "EXPORT_LOGS", logType: "Blocked_avatars", id: avatarId}));

    callback(true);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportAvatarsSaga(action) {
  const { type, callback } = action.payload;
  try {
    //const {data} = yield apiAvatarsService.exportAvatars();
    const {content = []} = yield select(getAmmMetaAvatars);
    console.log('exportAvatarsSaga', content);

    const exportInstance = yield ExportFactory.getExportHandler(type, content);
    yield exportInstance.prepareAvatars();
    yield exportInstance.export();

    callback(true);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* releaseAvatarsSaga(action) {
  const callback = action.payload;
  try {
    yield apiAvatarsService.releaseAvatars();
    callback(true);
  } catch (e) {
    callback(false);
    yield put(SettingsAction.handleAPIError(e));
  }
}

export function* watchAmm() {
  yield takeEvery(AmmAction.UPDATE_AVATAR_STATUS, updateAvatarStatus);
  yield takeEvery(AmmAction.UPDATE_AVATAR_QUARANTINE, updateAvatarQuarantine);
  yield takeEvery(AmmAction.UPDATE_META_AVATAR_INFO, updateProfileInfo);
  yield takeEvery(AmmAction.CREATE_META_AVATAR, createMetaAvatarSaga);
  yield takeEvery(AmmAction.UPDATE_META_AVATAR, updateMetaAvatarSaga);
  yield takeEvery(AmmAction.DELETE_META_AVATAR, deleteMetaAvatarSaga);
  yield takeEvery(AmmAction.GET_META_AVATARS, getMetaAvatarsSaga);
  yield takeEvery(AmmAction.GET_META_AVATAR, getMetaAvatarSaga);
  yield takeEvery(AmmAction.CREATE_PROFILE, createProfileSaga);
  yield takeEvery(AmmAction.DELETE_PROFILE, deleteProfileSaga);
  yield takeEvery(AmmAction.SAVE_DEFAULT_PROXY, saveDefaultProxySaga);
  yield takeEvery(AmmAction.GET_AUDIT_LOG, getAuditLogSaga);
  yield takeEvery(AmmAction.EXPORT_AUDIT_LOG, exportAuditLogSaga);
  yield takeEvery(AmmAction.GET_APA_LOGIN, getApaLoginSaga);
  yield takeEvery(AmmAction.GET_APA_POST, getApaPostSaga);
  yield takeEvery(AmmAction.EXPORT_ACTIVITIES, exportActivitiesSaga);
  yield takeEvery(AmmAction.EXPORT_BLOCKED, exportBlockedSaga);
  yield takeEvery(AmmAction.GET_META_AVATAR_STAT, getMetaAvatarStatSaga);
  yield takeEvery(AmmAction.EXPORT_AVATARS, exportAvatarsSaga);
  yield takeEvery(AmmAction.RELEASE_AVATARS, releaseAvatarsSaga);
}
